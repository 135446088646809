import { Link } from 'react-router-dom'

const Page404 = () => {
	const styleP = {
		textAlign: 'center',
		fontSize: '24px'
	}
	const styleLink = {
		...styleP,
		display: 'block'
	}
	return (
		<div>
			<p style={styleP}>Page doesn't exist</p>
			<Link to='..' relative='path' style={styleLink}>Back to main page</Link>
		</div>
	)
}

export default Page404;