import React from 'react';
import classes from './menu.module.scss';
import { Link } from 'react-router-dom';

const Links = ({ arr, title, pathname }) => {
  return (
    <li className={classes.menuList}>
      <ul className={classes.menuItems}>
        <li className={classes.menuTitle}>{title}</li>
        {arr.map((link) => (
          <li title={link.title} key={link.title} className={classes.menuItem}>
            {link.disabled ? (
              <Link disabled className={`${classes.menuLink}`}>
                {link.svg}
                <span>{link.title}</span>
              </Link>
            ) : (
              <Link
                to={link.to}
                className={`${classes.menuLink} ${
                  pathname.includes(`/admin/${link.to}`) ? classes._active : ''
                }`}
              >
                {link.svg}
                <span>{link.title}</span>
              </Link>
            )}
          </li>
        ))}
      </ul>
    </li>
  );
};

export default Links;
