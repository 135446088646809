// import ModalSupport from "../../modal/ModalSupport";
import { CSSTransition } from 'react-transition-group';
// import FormDonation from '../formDonation/FormDonation';

import './modal.scss';
import { Icon } from '../../common/iconSprite/IconSprite';
import img from './user.jpg';
import { useContext, useRef } from 'react';
import { ModalContext } from '../../../contexts/ModalContext';
import { useEffect } from 'react';
const Modal = () => {
    const {
        component,
        maxWidth,
        toggleShowModal,
        showModal,
        title,
        html,
        email,
        orcid,
        file,
        srcImage,
        setSrcImage,
        userInfo: user,
    } = useContext(ModalContext);
    const windowWidth = window.innerWidth;

    let mobile = windowWidth <= 767.98;
    const duration = 300;
    // console.log(user);
    const nodeRef = useRef(null);
    const userInfo = user ? (
        <div className="popup__user-info info-user">
            <div className="info-user__image-ibg">
                <img src={srcImage ? srcImage : img} alt="user" />
            </div>
            <div className="info-user__actions">
                {email && (
                    <a href={`mailto:${email}`} className="info-user__link">
                        <Icon name="user-mail" className="icon__user-mail" />
                    </a>
                )}

                {file && (
                    <a
                        href={file}
                        target="_blank"
                        rel="noreferrer"
                        className="info-user__link"
                    >
                        <Icon
                            name="user-download"
                            className="icon__user-download"
                        />
                    </a>
                )}
                {orcid && (
                    <a
                        href={orcid}
                        target="_blank"
                        rel="noreferrer"
                        className="info-user__link"
                    >
                        <Icon name="user-id" className="icon__user-id" />
                    </a>
                )}
            </div>
        </div>
    ) : null;

    return (
        <CSSTransition
            nodeRef={nodeRef}
            // key={id}
            // timeout={{ enter: duration, exit: duration }}
            in={showModal}
            timeout={duration}
            unmountOnExit
            // unmountOnEnter
            // onEnter={() => setShowTrigger(false)}
            // onExited={() => setShowTrigger(true)}
            classNames="modal"
        >
            <div ref={nodeRef} className="popup">
                <div className="popup__wrapper">
                    <div
                        style={{ maxWidth: maxWidth }}
                        className="popup__content"
                    >
                        {!mobile ? userInfo : null}
                        <div className="popup__body">
                            <div className="popup__header">
                                <h3 className="popup__title">{title}</h3>
                                <button
                                    onClick={toggleShowModal}
                                    data-close
                                    type="button"
                                    className="popup__close _icon-xmark"
                                ></button>
                            </div>
                            {mobile ? userInfo : null}
                            {!!component ? (
                                <div className="popup__info">{component}</div>
                            ) : (
                                <div
                                    className="popup__info"
                                    dangerouslySetInnerHTML={{ __html: html }}
                                ></div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default Modal;
