import PrivateRoute from '../components/common/privateRoute/PrivateRoute';
// import DiagramsPage from '../pages/adminPage/diagrams/DiagramsPage';

import { Icon } from '../components/common/iconSprite/IconSprite';
import classes from '../components/admin/UI/menu/menu.module.scss';
import React from 'react';
import {
    AboutPage,
    BackgroundPage,
    CommitteePage,
    DiagramsPage,
    FilesPage,
    NewsPage,
    ParticipantsPage,
    SpeakersPage,
    TimerPage,
    WelcomePage,
    PDFConstructorPage,
} from '../pages/adminPage';

// export const landingRoutes = [
//   [
//     { path: "diagrams", element: <PrivateRoute Component={Diagrams} /> },
//     { path: "/posts", element: <PrivateRoute Component={Diagrams} /> },
//     { path: "/posts/:id", element: <PrivateRoute Component={Diagrams} /> },
//     { path: "/error", element: <PrivateRoute Component={Diagrams} /> },
//   ],
// ];

export const menuLinks = {
    Statistics: [
        {
            to: 'diagrams',
            title: 'Diagrams',
            svg: <Icon name="link-diagrams" className={classes.menuImageIbg} />,
            element: <PrivateRoute Component={DiagramsPage} />,
        },
    ],
    'Landing page': [
        {
            to: 'welcome',
            title: 'Main section',
            svg: <Icon name="link-main" className={classes.menuImageIbg} />,
            element: <PrivateRoute Component={WelcomePage} />,
        },
        {
            to: 'event-countdown',
            title: 'Event countdown',
            svg: <Icon name="link-event" className={classes.menuImageIbg} />,
            element: <PrivateRoute Component={TimerPage} />,
        },
        {
            to: 'about-congress',
            title: 'About congress',
            svg: <Icon name="link-about" className={classes.menuImageIbg} />,
            element: <PrivateRoute Component={AboutPage} />,
        },
        {
            to: 'agenda',
            title: 'Agenda',
            svg: <Icon name="link-agenda" className={classes.menuImageIbg} />,
            element: <PrivateRoute Component={DiagramsPage} />,
            disabled: true,
        },
        {
            to: 'speakers',
            title: 'Speakers',
            svg: <Icon name="link-speakers" className={classes.menuImageIbg} />,
            element: <PrivateRoute Component={SpeakersPage} />,
            // disabled: true,
        },
        {
            to: 'committee',
            title: 'Committee',
            svg: (
                <Icon name="link-committee" className={classes.menuImageIbg} />
            ),
            element: <PrivateRoute Component={CommitteePage} />,
        },
        {
            to: 'dates-news',
            title: 'Dates & News',
            svg: (
                <Icon name="link-dates-news" className={classes.menuImageIbg} />
            ),
            element: <PrivateRoute Component={NewsPage} />,
        },
        {
            to: 'partners',
            title: 'Partners',
            svg: <Icon name="link-partners" className={classes.menuImageIbg} />,
            element: <PrivateRoute Component={DiagramsPage} />,
            disabled: true,
        },
    ],
    'Creating content': [
        {
            to: 'files',
            title: 'Files',
            svg: <Icon name="link-files" className={classes.menuImageIbg} />,
            element: <PrivateRoute Component={FilesPage} />,
        },
        {
            to: 'participants',
            title: 'Participants',
            svg: (
                <Icon
                    name="link-participants"
                    className={classes.menuImageIbg}
                />
            ),
            element: <PrivateRoute Component={ParticipantsPage} />,
        },
        {
            to: 'pdf-constructor',
            title: 'PDF constructor',
            svg: (
                <Icon
                    name="link-pdf-constructor"
                    className={classes.menuImageIbg}
                />
            ),
            element: <PrivateRoute Component={PDFConstructorPage} />,
        },
        {
            to: 'background',
            title: 'Background',
            svg: (
                <Icon name="link-background" className={classes.menuImageIbg} />
            ),
            element: <PrivateRoute Component={BackgroundPage} />,
            disabled: true,
        },
    ],
};
