import React, { useRef, useEffect, useState } from 'react';
import './scrollingContent.scss';

const ScrollingContent = ({ children }) => {
  const contentRef = useRef(null);
  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    const contentElement = contentRef.current;
    let animationFrame;

    const checkScrolling = () => {
      if (contentElement.scrollWidth > contentElement.clientWidth) {
        setShouldScroll(true);
      } else {
        setShouldScroll(false);
      }
    };

    const scrollContent = () => {
      if (shouldScroll) {
        cancelAnimationFrame(animationFrame);

        const scrollStep = 0.5;
        const scrollDelay = 1000;

        let scrollPosition = 0;
        let scrollDirection = 1;

        const animateScroll = () => {
          scrollPosition += scrollStep * scrollDirection;

          if (
            scrollPosition >=
            contentElement.scrollWidth - contentElement.clientWidth
          ) {
            // Достигнут конец контента, меняем направление на обратное
            scrollDirection = -1;
          } else if (scrollPosition <= 0) {
            // Достигнуто начало контента, меняем направление на прямое
            scrollDirection = 1;
          }

          contentElement.style.transform = `translateX(-${scrollPosition}px)`;

          animationFrame = requestAnimationFrame(animateScroll);
        };

        setTimeout(() => {
          animateScroll();
        }, scrollDelay); // Задержка в миллисекундах перед началом анимации
      }
    };

    const resizeObserver = new ResizeObserver(checkScrolling);
    resizeObserver.observe(contentElement);

    scrollContent();

    return () => {
      resizeObserver.unobserve(contentElement);
      cancelAnimationFrame(animationFrame);
    };
  }, [shouldScroll]);
  return (
    <div className="scrolling-container">
      <div className="scrolling-content" ref={contentRef}>
        {children}
      </div>
    </div>
  );
};

export default ScrollingContent;
