import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useVerifyTokenMutation } from '../../../services/api/apiVerifyToken';
import Spinner from '../spinner/Spinner';

const PrivateRoute = ({ Component, ...props }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const token = localStorage.getItem('isc-admin-token');

  const [checkAuth] = useVerifyTokenMutation();
  useEffect(() => {
    checkAuth(token)
      .then((res) => {
        if (res.data.message === true) {
          setIsLoading(false);
          setIsAuth(true);
        } else {
          setIsLoading(false);
          setIsAuth(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setIsAuth(false);
      });
  }, [token, checkAuth]);
  if (isLoading) {
    return (
      <div
        style={{
          display: 'grid',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner />
      </div>
    );
  }
  if (isAuth) {
    return <Component {...props} />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default PrivateRoute;
