import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from "./config";

export const apiCustomerSatisfactionIndexDiagram = createApi({
  reducerPath: 'apiCustomerSatisfactionIndexDiagram',
  baseQuery: fetchBaseQuery({ baseUrl: config.apiURL }),
  tagTypes: ['UserDiagrams'],
  endpoints: (builder) => ({
    getUserDiagrams: builder.query({
      query: () => '/poll/csi/',
      providesTags: ['UserDiagrams'],
    }),
    getUserDiagram: builder.query({
      query: (id) => `/poll/csi/${id}`,
      providesTags: ['UserDiagrams'],
    }),
    createUserDiagrams: builder.mutation({
      query: (diagrams) => ({
        url: '/poll/csi/',
        method: 'POST',
        body: diagrams,
      }),
      invalidatesTags: ['UserDiagrams'],
    }),
    updateUserDiagrams: builder.mutation({
      query: (body) => {
        return { url: `/poll/csi/`, method: 'PUT', body: body };
      },
      invalidatesTags: ['UserDiagrams'],
    }),
    deleteUserDiagrams: builder.mutation({
      query: (id) => ({
        url: `/poll/csi/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserDiagrams'],
    }),
  }),
});

export const {
  useGetUserDiagramQuery,
  useGetUserDiagramsQuery,
  useDeleteUserDiagramsMutation,
  useCreateUserDiagramsMutation,
  useUpdateUserDiagramsMutation,
} = apiCustomerSatisfactionIndexDiagram;
