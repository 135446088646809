import { Outlet } from 'react-router-dom';
import { LoadingAuthProvider } from '../contexts/LoadingAuthContext';

const LoginLayout = () => {
  return (
    <>
      <LoadingAuthProvider>
        <Outlet />
      </LoadingAuthProvider>
    </>
  );
};

export default LoginLayout;
