import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from "./config";

export const apiPdftemplates = createApi({
    reducerPath: 'apiPdftemplates',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiURL,
    }),
    tagTypes: ['Pdftemplates'],
    endpoints: (builder) => ({
        getPdftemplates: builder.query({
            query: () => '/pdftemplates',
            providesTags: ['Pdftemplates'],
        }),
        getOnePdftemplates: builder.query({
            query: (id) => `/pdftemplates/${id}`,
            providesTags: ['Pdftemplates'],
        }),
        createPdftemplate: builder.mutation({
            query: (pdftemplates) => ({
                url: '/pdftemplates',
                method: 'POST',
                body: pdftemplates,
            }),
            invalidatesTags: ['Pdftemplates'],
        }),
        updatePdftemplate: builder.mutation({
            query: (item) => {
                const { id, body, token } = item;
                return {
                    url: `/pdftemplates/${id}`,
                    method: 'PUT',
                    headers: {
                        auth: `Bearer ${JSON.parse(token)}`,
                    },
                    body: body,
                };
            },
            invalidatesTags: ['Pdftemplates'],
        }),
        deletePdftemplate: builder.mutation({
            query: (id) => ({
                url: `/pdftemplates/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Pdftemplates'],
        }),
    }),
});

export const {
    useGetPdftemplatesQuery,
    useGetOnePdftemplatesQuery,
    useCreatePdftemplateMutation,
    useDeletePdftemplateMutation,
    useUpdatePdftemplateMutation,
} = apiPdftemplates;
