import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from "./config";

export const apiAbout = createApi({
  reducerPath: 'apiAbout',
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiURL,
    // prepareHeaders(headers, { getState }) {
    // 	const token = localStorage.getItem('isc-admin-token');  // Получите JWT-токен
    // 	// Добавьте токен в заголовки запроса
    // 	const mergedHeaders = new Headers(headers);
    // 	mergedHeaders.append('auth', `Bearer ${token}`);
    // 	mergedHeaders.set('Content-Type', 'application/json');
    // 	mergedHeaders.set('Access-Control-Allow-Credentials', 'true');
    // 	mergedHeaders.set('Access-Control-Allow-Headers', 'X-Requested-With,content-type');
    // 	mergedHeaders.set('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE');
    // 	mergedHeaders.set('Access-Control-Allow-Origin', '*');
    // 	mergedHeaders.set('Connection', 'keep-alive');
    // 	mergedHeaders.set('Content-Encoding', 'gzip');
    // 	mergedHeaders.set('Date', new Date().toUTCString());
    // 	mergedHeaders.set('Keep-Alive', 'timeout=60');
    // 	mergedHeaders.set('Server', 'nginx');
    // 	mergedHeaders.set('Transfer-Encoding', 'chunked');
    // 	mergedHeaders.set('Vary', 'Accept-Encoding');
    // 	mergedHeaders.set('X-Powered-By', 'Express');
    // 	console.log(mergedHeaders)

    // 	return mergedHeaders;

    // },
  }),
  tagTypes: ['About'],
  endpoints: (builder) => ({
    getAbouts: builder.query({
      query: () => '/about',
      providesTags: ['About'],
    }),
    getAbout: builder.query({
      query: (id) => `/about/${id}`,
      providesTags: ['About'],
    }),
    createAbout: builder.mutation({
      query: (about) => ({
        url: '/about',
        method: 'POST',
        body: about,
      }),
      invalidatesTags: ['About'],
    }),
    updateAbout: builder.mutation({
      query: (item) => {
        const { id, body, token } = item;
        return {
          url: `/about/${id}`,
          method: 'PUT',
          headers: {
            auth: `Bearer ${JSON.parse(token)}`,
          },
          body: body,
        };
      },
      invalidatesTags: ['About'],
    }),
    deleteAbout: builder.mutation({
      query: (id) => ({
        url: `/about/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['About'],
    }),
  }),
});

export const {
  useGetAboutsQuery,
  useCreateAboutMutation,
  useDeleteAboutMutation,
  useGetAboutQuery,
  useUpdateAboutMutation,
} = apiAbout;
