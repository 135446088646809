import { Outlet } from 'react-router-dom';
import { IconSprite } from '../components/common/iconSprite/IconSprite';
import { Header, Footer } from '../components/index';

// Головний макет сторінки

const MainLayout = () => {
  return (
    <>
      <IconSprite /> {/* Компонент для відображення спрайту з іконками */}
      <Header /> {/* Верхній колонтитул */}
      <main className="page">
        {/* Головний контент */}
        <Outlet /> {/* Відображення вкладок маршрутизатора */}
      </main>
      <Footer /> {/* Нижній колонтитул */}
    </>
  );
};

export default MainLayout;
