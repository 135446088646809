import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AdminLayout, LoginLayout, MainLayout } from './layouts';
import Page404 from './pages/404/Page404';
import { Login } from './pages/adminPage';
import { menuLinks } from './routes/routes';
import { PrivateRoute } from './components';
import { LoadingAuthProvider } from './contexts/LoadingAuthContext';
import { Home } from './pages/landingPage';

function App() {
  return (
    <Router>
      <Routes>
        {/* Маршрут для головної сторінки */}
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
        </Route>

        {/* Маршрути для сторінок адміністратора */}
        <Route
          path="admin"
          element={
            <LoadingAuthProvider>
              <PrivateRoute Component={AdminLayout} />
            </LoadingAuthProvider>
          }
        >
          {Object.keys(menuLinks).map((key) =>
            menuLinks[key].map((route) => (
              <Route path={route.to} element={route.element} />
            ))
          )}
        </Route>

        {/* Маршрут для сторінки входу */}
        <Route path="login" element={<LoginLayout />}>
          <Route index element={<Login />} />
        </Route>

        {/* Маршрут для сторінки 404 */}
        <Route path="*" element={<Page404 />} />
      </Routes>
      {/* <ScrollToTop /> */}
    </Router>
  );
}

export default App;
