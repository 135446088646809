import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from "./config";

// Створення API для модулів "Committees" та "Buttons"
export const apiCommittees = createApi({
  reducerPath: 'apiCommittees', // Шлях до редуктора для обробки стану API
  baseQuery: fetchBaseQuery({ baseUrl: `${config.apiURL}/committee` }), // Базовий запит з базовою URL
  tagTypes: ['Committees', 'Buttons'], // Типи тегів для кешування
  endpoints: (builder) => ({
    // Запит для отримання всіх даних "Committees"
    getCommittees: builder.query({
      query: () => '/committees', // Шлях до ендпоінта
      providesTags: ['Committees'], // Теги, які надаються для цього запиту
    }),
    // Запит для отримання конкретного елемента "Committees" за його ідентифікатором
    getCommittee: builder.query({
      query: (id) => `/committees/${id}`, // Шлях до ендпоінта з переданим ідентифікатором
      providesTags: ['Committees'], // Теги, які надаються для цього запиту
    }),
    // Запит для створення нового елемента "Committees"
    createCommittees: builder.mutation({
      query: (committees) => ({
        url: '/committees', // Шлях до ендпоінта
        method: 'POST', // HTTP-метод POST для створення ресурсу
        body: committees, // Вміст запиту
      }),
      invalidatesTags: ['Committees'], // Теги, які стають недійсними після успішного виконання запиту
    }),
    // Запит для оновлення існуючого елемента "Committees"
    updateCommittees: builder.mutation({
      query: (item) => {
        const { token, id, body } = item;
        return {
          url: `/committees/${id}`, // Шлях до ендпоінта з переданим ідентифікатором
          method: 'PUT', // HTTP-метод PUT для оновлення ресурсу
          headers: {
            auth: `Bearer ${JSON.parse(token)}`,
          },
          body: body, // Вміст запиту
        };
      },
      invalidatesTags: ['Committees'], // Теги, які стають недійсними після успішного виконання запиту
    }),
    // Запит для видалення елемента "Committees"
    deleteCommittees: builder.mutation({
      query: (id) => ({
        url: `/committees/${id}`, // Шлях до ендпоінта з переданим ідентифікатором
        method: 'DELETE', // HTTP-метод DELETE для видалення ресурсу
      }),
      invalidatesTags: ['Committees'], // Теги, які стають недійсними після успішного виконання запиту
    }),
    // Запит для отримання всіх даних "Buttons"
    getButtons: builder.query({
      query: () => '/buttons', // Шлях до ендпоінта
      providesTags: ['Buttons'], // Теги, які надаються для цього запиту
    }),
    // Запит для отримання конкретного елемента "Buttons" за його ідентифікатором
    getButton: builder.query({
      query: (id) => `/buttons/${id}`, // Шлях до ендпоінта з переданим ідентифікатором
      providesTags: ['Buttons'], // Теги, які надаються для цього запиту
    }),
    // Запит для створення нового елемента "Buttons"
    createButton: builder.mutation({
      query: (button) => ({
        url: '/buttons', // Шлях до ендпоінта
        method: 'POST', // HTTP-метод POST для створення ресурсу
        body: button, // Вміст запиту
      }),
      invalidatesTags: ['Buttons'], // Теги, які стають недійсними після успішного виконання запиту
    }),
    // Запит для оновлення існуючого елемента "Buttons"
    updateButton: builder.mutation({
      query: (item) => {
        const { id, token, body } = item;
        return {
          url: `/buttons/${id}`, // Шлях до ендпоінта з переданим ідентифікатором
          method: 'PUT', // HTTP-метод PUT для оновлення ресурсу
          headers: {
            auth: `Bearer ${JSON.parse(token)}`,
          },
          body: body, // Вміст запиту
        };
      },
      invalidatesTags: ['Buttons'], // Теги, які стають недійсними після успішного виконання запиту
    }),
    // Запит для видалення елемента "Buttons"
    deleteButton: builder.mutation({
      query: (id) => ({
        url: `/buttons/${id}`, // Шлях до ендпоінта з переданим ідентифікатором
        method: 'DELETE', // HTTP-метод DELETE для видалення ресурсу
      }),
      invalidatesTags: ['Buttons'], // Теги, які стають недійсними після успішного виконання запиту
    }),
  }),
});

// Експорт створених хуків для взаємодії з API "Committees" та "Buttons"
export const {
  useGetButtonQuery,
  useGetButtonsQuery,
  useGetCommitteeQuery,
  useGetCommitteesQuery,
  useCreateButtonMutation,
  useCreateCommitteesMutation,
  useDeleteButtonMutation,
  useDeleteCommitteesMutation,
  useUpdateCommitteesMutation,
  useUpdateButtonMutation,
} = apiCommittees;
