import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './assets/scss/index.scss';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import { Modal, Spinner } from './components';
import { ModalProvider } from './contexts/ModalContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <Suspense
      fallback={
        <div
          style={{
            display: 'grid',
            minHeight: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      }
    >
      <ModalProvider>
        <App />
      </ModalProvider>
    </Suspense>
  </Provider>
  //</React.StrictMode>
);
